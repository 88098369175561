// Add your JS customizations here

// Function to check if the user has scrolled and toggle the visibility of elements
function toggleElements() {
    var scrollTop = window.scrollY;

    // Adjust the scroll position where you want the toggle to happen
    var scrollThreshold = 100;

    // Get the elements by their IDs
    var element1 = document.getElementById('phone-item');
    var element2 = document.getElementById('envelope-item');
    var element3 = document.getElementById('action-header-contact');

    // Toggle visibility based on scroll position
    if (scrollTop > scrollThreshold) {
        element1.classList.add("sticky-header");
        element2.classList.add("sticky-header");
        element1.classList.remove("hide");
        element2.classList.remove("hide");
        element3.style.display = 'none';
    } else {
        element1.classList.remove("sticky-header");
        element2.classList.remove("sticky-header");
        element1.classList.add("hide");
        element2.classList.add("hide");
        element3.style.display = 'flex';
    }
}

// Attach the toggleElements function to the scroll event
jQuery(document).ready(function($){
    if( $('body.home').length ) {
        window.addEventListener('scroll', toggleElements);
    }


    // if ( $('.usp-slider').length > 0 ) {
    //     // https://github.com/ganlanyuan/tiny-slider/issues/154
    //     // For creating inline data attributes
    //     var logoCarousel = document.querySelectorAll('.usp-slider');
    //     console.log(logoCarousel);
        
    //     logoCarousel.forEach((logoCarousel) => {
    //         var opt              = JSON.parse(logoCarousel.getAttribute('data-tiny-slider'));
    //         opt.container        = logoCarousel;
    //         opt.gutter           = 15;
    //         opt.mouseDrag        = true;
             
    //         opt.responsive = {
    //             0 : {
    //                 items: 1
    //             },
    //             767 : {
    //                 items: 3
    //             },
    //             1000 : {
    //                 items: 4
    //             },
    //             1400 : {
    //                 items: 4
    //             }
    //         }
            
    //         var slider = tns(opt);
    //     });	
    // }

    if ( $('.usp-top-slider').length > 0 ) {
        /* TinySlider */
        var usp = tns({
            container: '.usp-top-slider',
            gutter: 5,
            mouseDrag: true,
            autoplay: true,
            speed: 500,
            autoplayButtonOutput: false,
            nav: false,
            controls: false,

            responsive: {
                0: {
                    items: 1
                },
                500: {
                    items: 1
                },
                767: {
                    items: 2
                },
                1000: {
                    items: 3
                },
                1300: {
                    items: 3
                },
                1500: {
                    items: 4
                }
            }
        });
    }


    if ( $('.usp-slider').length > 0 ) {
        /* TinySlider */
        var usp = tns({
            container: '.usp-slider',
            gutter: 5,
            mouseDrag: true,
            autoplay: true,
            speed: 500,
            autoplayButtonOutput: false,
            nav: false,
            controls: false,
            responsive: {
                0: {
                    items: 1
                },
                500: {
                    items: 1
                },
                767: {
                    items: 2
                },
                1000: {
                    items: 3
                },
                1300: {
                    items: 3
                },
                1500: {
                    items: 4
                }
            }
        });
    }

    if ( $('.homepage-slider').length > 0 ) {
        /* TinySlider */
        var usp = tns({
            container: '.homepage-slider',
            items: 1,
            mouseDrag: true,
            autoplay: true,
            speed: 500,
            autoplayTimeout: 10000,
            autoplayButtonOutput: false,
            nav: false,
            controlsContainer: '.tns-controls',
            mode: 'gallery',
        });
    }

    
    if ( $('.feature-products-slide').length > 0 ) {
        /* TinySlider */
        var usp = tns({
            container: '.feature-products-slide',
            items: 1,
            mouseDrag: false,
            autoplay: true,
            speed: 500,
            autoplayTimeout: 10000,
            autoplayButtonOutput: false,
            nav: false,
            controlsContainer: '.feature-products',
            mode: 'gallery',
        });
    }

    if ( $('.reviews-slide').length > 0 ) {
        /* TinySlider */
        var usp = tns({
            container: '.reviews-slide',
            gutter: 100,
            mouseDrag: false,
            autoplay: true,
            speed: 500,
            autoplayTimeout: 10000,
            autoplayButtonOutput: false,
            nav: false,
            controlsContainer: '.reviews-controls',
            responsive: {
                0: {
                    gutter: 0,
                    items: 1
                },
                991: {
                    gutter: 100,
                    items: 2
                }
            }
        });
    }

    if ( $('.companies-work-slide').length > 0 ) {
        /* TinySlider */
        var usp = tns({
            container: '.companies-work-slide',
            mouseDrag: true,
            autoplay: true,
            gutter: 50,
            speed: 500,
            autoplayTimeout: 10000,
            autoplayButtonOutput: false,
            nav: false,
            controlsContainer: '.companies-work-controls',
            responsive: {
                0: {
                    items: 1
                },
                400: {
                    items: 2
                },
                576: {
                    items: 4
                },
                992: {
                    items: 3
                },
                1299: {
                    items: 4
                }
            }
        });
    }

    if ( $('.blog-cta-slide').length > 0 ) {
        /* TinySlider */
        var usp = tns({
            container: '.blog-cta-slide',
            items: 1,
            mouseDrag: false,
            autoplay: true,
            speed: 500,
            autoplayTimeout: 10000,
            autoplayButtonOutput: false,
            nav: false,
            controlsContainer: '.blog-controls'
        });
    }

    if ( $('.blog-normal-slide').length > 0 ) {
        /* TinySlider */
        var usp = tns({
            container: '.blog-normal-slide',
            items: 1,
            mouseDrag: false,
            autoplay: true,
            speed: 500,
            autoplayTimeout: 10000,
            autoplayButtonOutput: false,
            nav: false,
            controlsContainer: '.blog-normal-controls'
        });
    }

    if ( $('.blog-cards-slide').length > 0 ) {
        /* TinySlider */
        var usp = tns({
            container: '.blog-cards-slide',
            items: 3,
            gutter: 60,
            mouseDrag: false,
            autoplay: true,
            speed: 500,
            autoplayTimeout: 10000,
            autoplayButtonOutput: false,
            nav: false,
            controlsContainer: '.blog-cards-controls',
            responsive: {
                0: {
                    items: 1
                },
                400: {
                    items: 1
                },
                576: {
                    items: 1
                },
                992: {
                    items: 3
                }
            }
        });
    }

    if ( $('.project-slide').length > 0 ) {
        /* TinySlider */
        var usp = tns({
            container: '.project-slide',
            gutter: 60,
            mouseDrag: false,
            autoplay: true,
            speed: 500,
            autoplayTimeout: 10000,
            autoplayButtonOutput: false,
            nav: false,
            controlsContainer: '.project-controls',
            responsive: {
                0: {
                    items: 1
                },
                500: {
                    items: 1
                },
                767: {
                    items: 2
                },
                1000: {
                    items: 3
                },
                1300: {
                    items: 3
                }
            }
        });
    }

    if ( $('.product-gallery-slider').length > 0 ) {
        /* TinySlider */
        var usp = tns({
            container: '.product-gallery-slider',
            gutter: 60,
            mouseDrag: true,
            autoplay: true,
            speed: 500,
            autoplayTimeout: 10000,
            autoplayButtonOutput: false,
            nav: true,
            controls: false,
            responsive: {
                0: {
                    items: 1
                },
                500: {
                    items: 1
                },
                767: {
                    items: 2
                },
                1000: {
                    items: 3
                },
                1300: {
                    items: 4
                }
            }
        });
    }

    if ( $('.lightbox-gallery').length > 0 ) {
			
        var lightboxes = document.querySelectorAll('.lightbox-gallery');
        
        lightboxes.forEach((lightboxes) => {
            lightGallery(lightboxes, {
                speed: 500,
                selector: '.lightbox-gallery__link'
            });
        });
    }

    if ( $('.map-block').length > 0 ) {
        var mapUk = L.map('map-uk').setView([52.846818, -1.226062], 6);
        L.tileLayer('https://api.mapbox.com/styles/v1/rubensardas/clrqa507b00az01pl73dp86nc/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicnViZW5zYXJkYXMiLCJhIjoiY2xycTlhcjAxMDBxbTJsbnoxd3IwN2ozYyJ9.T_DIE3zIEhdJ-dj8-J_ebQ', {
            maxZoom: 6,
            minZoom: 6
        }).addTo(mapUk);

        var customMarker = L.icon({
            iconUrl: 'https://wordpress-314780-4254836.cloudwaysapps.com/wp-content/uploads/2024/01/map-marker.svg',
            iconSize:     [30, 30], // size of the icon
            iconAnchor:   [15, 30], // point of the icon which will correspond to marker's location
            popupAnchor:  [0, -25] // point from which the popup should open relative to the iconAnchor
        }); 

        L.marker([52.565799, -0.243114], {icon: customMarker}).addTo(mapUk).bindPopup("Peterborough, UK");
        L.marker([52.506246, 0.083491], {icon: customMarker}).addTo(mapUk).bindPopup("Whittlesey, UK");

        var mapAu = L.map('map-au').setView([-20.240169, 140.088059], 3);
        L.tileLayer('https://api.mapbox.com/styles/v1/rubensardas/clrqa507b00az01pl73dp86nc/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicnViZW5zYXJkYXMiLCJhIjoiY2xycTlhcjAxMDBxbTJsbnoxd3IwN2ozYyJ9.T_DIE3zIEhdJ-dj8-J_ebQ', {
            maxZoom: 5,
            minZoom: 3
        }).addTo(mapAu);

        mapUk.scrollWheelZoom.disable();
        mapAu.scrollWheelZoom.disable();

        L.marker([14.594497, 120.985404], {icon: customMarker}).addTo(mapAu).bindPopup("Manilla, Philippines");
        L.marker([-34.983312, 138.609340], {icon: customMarker}).addTo(mapAu).bindPopup("Adelaide, Australia");
        L.marker([-37.817996, 144.959985], {icon: customMarker}).addTo(mapAu).bindPopup("Melbourne, Australia");
        L.marker([-33.872374, 151.209033], {icon: customMarker}).addTo(mapAu).bindPopup("Sydney, Australia");
        L.marker([-37.281365, 174.924301], {icon: customMarker}).addTo(mapAu).bindPopup("Auckland, New Zealand");
    }
}); 